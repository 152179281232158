<template>
  <div class="layer-page-div">
    <div class="layer-content-div">
      <div class="item-div">
        <div class="item-name-div">用户昵称：</div>
        <div>{{ commentInfo.userNickname }}</div>
      </div>
      <div class="item-div">
        <div class="item-name-div">评论时间：</div>
        <div>{{ commentInfo.createTime }}</div>
      </div>
      <div class="item-txt-div">
        <div class="item-name-div">评论内容：</div>
        <div>
          <div class="msg-div">{{ commentInfo.content }}</div>
        </div>
      </div>
      <template v-if="isReply">
        <div class="item-div reply-div">
          <div class="item-name-div">回复时间：</div>
          <div>{{ commentInfo.replyTime }}</div>
        </div>
        <div class="item-txt-div">
          <div class="item-name-div">回复内容：</div>
          <div>
            <div class="msg-div">{{ commentInfo.replyContent }}</div>
          </div>
        </div>
      </template>
      <div v-else class="reply-div item-txt-div">
        <div class="item-name-div">回复内容：</div>
        <div>
          <label>
            <textarea v-model="replyMsg" maxlength="200" placeholder="请输入回复内容"/>
          </label>
        </div>
      </div>
      <div class="operate-div">
        <div class="tips-div text-c err-tips">{{ tipMsg }}</div>
        <div class="text-c">
          <input type="button" value="取消" @click="onCancel">
          <input v-if="!isReply" class="save-input" type="button" value="回复" @click="onReply">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { postFirmCommentReply } from "../../common/api";

export default {
  props: {
    layerid: {
      type: String,
      default: ""
    },
    commentInfo: {
      type: Object,
      default: () => {}
    },
    refreshData: {
      type: Function,
      default: () => {}
    }
  },
  data() {
    return {
      replyMsg: "",
      tipMsg: ""
    }
  },
  computed: {
    isReply() {
      return Object.prototype.hasOwnProperty.call(this.commentInfo, "replyTime")
    }
  },
  methods: {
    onCancel() {
      this.$layer.close(this.layerid);
    },
    onReply() {
      if(this.replyMsg.length < 1){
        this.setTipsMsg("请输入回复内容");
        return;
      }
      if(this.replyMsg.length > 200){
        this.setTipsMsg("请回复内容不能超过200个字符");
        return;
      }

      postFirmCommentReply({id: this.commentInfo.id, reply: this.replyMsg})
        .then(() => {
          this.refreshData();
          this.$layer.close(this.layerid);
          this.$layer.msg("操作成功");
        })
        .catch(error => {
          if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
            this.$layer.msg(error.data.msg);
          } else {
            this.$layer.msg("操作失败");
          }
        });
    },
    setTipsMsg(msg) {
      this.tipMsg = msg;
      clearTimeout(this.timeCounter);
      this.timeCounter = setTimeout(() => {
        this.tipMsg = "";
      }, 3000);
    }
  }
}
</script>

<style lang="less" scoped>
.layer-page-div {
  width: 100%;
  font-size: 14px;
  color: #2c3e50;
  .layer-content-div {
    width: 95%;
    margin: 20px auto 0 auto;
    .item-div {
      display: flex;
      align-items: center;
      margin-top: 10px;
    }
    .item-txt-div {
      display: flex;
      margin-top: 10px;
      .msg-div {
        width: 322px;
        height: 92px;
        padding: 3px;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
        overflow-y: auto;
      }
      textarea {
        width: 322px;
        height: 92px;
        resize: none;
        padding: 3px;
        border: 1px solid #dcdcdc;
        border-radius: 3px;
      }
    }
    .item-name-div {
      width: 100px;
      text-align: right;
    }
    .reply-div {
      margin-top: 30px;
    }
    .operate-div {
      margin-top: 20px;
      input {
        width: 120px;
        height: 35px;
        color: white;
        font-size: 16px;
        border: none;
        border-radius: 17px;
        background-color: #002345;
        margin-top: 10px;
      }
      .save-input {
        margin-left: 5px;
      }
      .tips-div {
        width: 100%;
        height: 25px;
        margin-top: 10px;
      }
    }
  }
}
</style>