<template>
  <div class="child-page-div">
    <div class="page-content-div">
      <table-box ref="tableBox" tableTitle="评论管理" :tableFieldNum="tableFieldNum" :action="action" :params="queryParams" :reloadData.sync="reloadData">
        <template #table-head>
          <th v-for="(item, index) in tableField" v-show="item.state" :key="index">{{ item.alias }}</th>
          <th class="operation-th">操作</th>
        </template>
        <template #table-body="props">
          <tr>
            <template v-for="(item, index) in tableField">
              <td v-if="item.isHtml" :key="index" v-show="item.state" v-html="item.value(props.item, item.name)" />
              <td v-else :key="index" v-show="item.state">{{ item.value(props.item, item.name) }}</td>
            </template>
            <td>
              <div class="operation-btn-div">
                <span class="cursor-el" @click="onShowItem(props.item)">查看</span>
                <span class="cursor-el" @click="onDelItem(props.item)">删除</span>
              </div>
            </td>
          </tr>
        </template>
      </table-box>
    </div>
  </div>
</template>

<script>
import TableBox from "../components/TableBox";
import FirmCommentLayer from "./layer/FirmCommentLayer";
import { getFirmCommentItems, postFirmCommentDel } from "../common/api";

export default {
  name: "Comments",
  components: { TableBox },
  data() {
    return {
      action: getFirmCommentItems,
      reloadData: false,
      queryParams: {
        type: 0,
        msg: "",
        pageSize: 20
      },
      tableField: [
        { state: true, alias: "编号", name: "id", title: "id", isHtml: false, value: this.getObjValue },
        { state: true, alias: "用户昵称", name: "userNickname", title: "userNickname", isHtml: false, value: this.getObjValue },
        { state: true, alias: "评论内容", name: "content", title: "content", isHtml: true, value: this.getObjValue },
        { state: true, alias: "评论时间", name: "createTime", title: "createTime", isHtml: false, value: this.getObjValue }
      ]
    };
  },
  computed: {
    tableFieldNum() {
      let num = 1;
      this.tableField.forEach(function(value) {
        if (value.state) {
          ++num;
        }
      });
      return num;
    }
  },
  methods: {
    getObjValue(item, field) {
      if(field === "content"){
        return `<div class="ellipsis-content-div">${item[field]}</div>`;
      }else{
        return item[field];
      }
    },
    onReloadData() {
      this.reloadData = true;
    },
    onShowItem(item) {
      this.$layer.iframe({
        content: {
          content: FirmCommentLayer,
          parent: this,
          data: {
            commentInfo: item,
            refreshData: this.onReloadData
          }
        },
        area: ["550px", "500px"],
        title: "评论详情"
      });
    },
    onDelItem(item) {
      this.$layer.confirm("是否删除该条评论？", layerid => {
        postFirmCommentDel({ id: item.id })
          .then(() => {
            this.$layer.msg("操作成功");
            this.onReloadData();
            this.$layer.close(layerid);
          })
          .catch(error => {
            this.$layer.close(layerid);
            if (Object.prototype.hasOwnProperty.call(error, "data") && Object.prototype.hasOwnProperty.call(error.data, "status")) {
              this.$layer.msg(error.data.msg);
            } else {
              this.$layer.msg("操作失败");
            }
          });
      });
    }
  }
}
</script>

<style lang="less" scoped>
.child-page-div {
  width: 100%;
  .page-content-div {
    border-radius: 5px;
    background-color: #f7f7fa;
    padding: 30px 20px 10px 20px;
    .operation-th {
      width: 110px;
    }
    .operation-btn-div {
      display: flex;
      justify-content: space-around;
    }
  }
}
</style>